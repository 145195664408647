// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useRef, useMemo } from "react";
import * as SVG from 'svg.js';

import SVGFootballFieldTemplate from './assets/football-field-template.svg';
export const FootballFieldTemplate = React.memo((): JSX.Element => <SVGFootballFieldTemplate />);

import SVGMenLacrosseFieldTemplate from './assets/men-lacrosse-field-template.svg';
export const MenLacrosseFieldTemplate = React.memo((): JSX.Element => <SVGMenLacrosseFieldTemplate />);


// export default function MyFieldImage() {
//     const SVGWrapperRefElement = useRef(null);
//     const SVGContainer = useMemo(() => SVG(), []);

//     const draw = () => {
//         SVGContainer.add(SVG().resize(550, 300).svg(<SVGFootballFieldTemplate />));
//     };

//     const clear = () => {
//         SVGContainer.clear();
//     };

//     useEffect(() => {
//         if (
//             SVGWrapperRefElement &&
//             SVGWrapperRefElement?.current &&
//             SVGWrapperRefElement?.current?.children.length < 1
//         ) {
//             SVGContainer.addTo(SVGWrapperRefElement?.current);
//         }
//     }, [SVGWrapperRefElement, SVGContainer]);

//     return (
//         <div id="field">
//             <button onClick={draw}>Draw</button>
//             <button onClick={clear}>Clear</button>
//             <svg ref={SVGWrapperRefElement} />
//         </div>
//     );
// }



// export const MyImage = React.memo(() => {
//     const svgRef = useRef<SVGSVGElement | null>(null);
//     const drawRef = useRef<SVG.Doc | null>(null);

//     useEffect(() => {
//         // Load the SVG image using SVG.js
//         if (svgRef.current) {
//             const draw = SVG(svgRef.current);
//             drawRef.current = draw;
//             setupInteractions(draw); // Setup interactions on the SVG elements
//         }
//     }, []);

//     const setupInteractions = (draw: SVG.Doc) => {
//         // Example: Add a circle on click
//         draw.on('click', (event: MouseEvent) => {
//             const point = draw.point(event.offsetX, event.offsetY);
//             const circle = draw.circle(20).center(point.x, point.y).fill('blue');
//             // Store the data or perform other actions based on the click event
//             // For example, you can use React state to store data:
//             // useState((prevState) => [...prevState, { x: point.x, y: point.y }]);
//         });

//         // Example: Add more interactions here...
//     };

//     return <svg ref={svgRef} dangerouslySetInnerHTML={{ __html: SVGFootballFieldTemplate }} />;
// });
