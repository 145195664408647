// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import _cvat from 'cvat-core/src/api';
import { connect } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Button from 'antd/lib/button';
import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';
import notification from 'antd/lib/notification';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import openCVWrapper from 'utils/opencv-wrapper/opencv-wrapper';
import { CombinedState, ActiveControl, ObjectType, ShapeType } from 'reducers';
import { createAnnotationsAsync, storeHomographyPoint } from 'actions/annotation-actions';
import { getCore, Label } from 'cvat-core-wrapper';
import { Canvas } from 'cvat-canvas-wrapper';
import { HomographyIcon } from 'icons';
import _ from 'lodash';

import withVisibilityHandling from './handle-popover-visibility';
import { FootballFieldTemplate, MenLacrosseFieldTemplate } from 'field-templates';

const core = getCore();

function footballTemplatePoints() {
    let points = [];
    const x_ticks = [
        102, 202, 302, 402, 502, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1698, 1798, 1898, 1998,
        2098,
    ];
    const y_ticks = [66, 201, 466, 733, 998, 1133];
    for (let ix = 0; ix < x_ticks.length; ix++) {
        for (let iy = 0; iy < y_ticks.length; iy++) {
            if ((y_ticks[iy] !== 201 && y_ticks[iy] !== 998) || (2 <= ix && ix <= 18 && ix % 2 === 0)) {
                points.push([x_ticks[ix] / 2, y_ticks[iy] / 2]);
            }
        }
    }
    return points;
}

function menLacrosseTemplatePoints() {
    let points = [];
    const x_ticks = [
        0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900,
        2000, 2100, 2200,
    ];
    const y_ticks = [0, 66, 201, 997, 1133, 1200];
    for (let ix = 0; ix < x_ticks.length; ix++) {
        for (let iy = 0; iy < y_ticks.length; iy++) {
            if ((y_ticks[iy] === 201 || y_ticks[iy] === 997) && (x_ticks[ix] === 800 || x_ticks[ix] === 1400)) {
                continue;
            }
            points.push([x_ticks[ix] / 2, y_ticks[iy] / 2]);
        }
    }
    points.push([300 / 2, 540 / 2]);
    points.push([300 / 2, 657 / 2]);
    points.push([1900 / 2, 540 / 2]);
    points.push([1900 / 2, 657 / 2]);
    points.push([1100 / 2, 600 / 2]);

    return points;
}

const fieldTemplatePoints = {
    football: footballTemplatePoints(),
    'men-lacrosse': menLacrosseTemplatePoints(),
};

const cornerPoints = {
    full: [
        [0, 0],
        [1100, 0],
        [1100, 600],
        [0, 600],
    ],
    left: [
        [0, 0],
        [552, 0],
        [552, 600],
        [0, 600],
    ],
    right: [
        [548, 0],
        [1100, 0],
        [1100, 600],
        [548, 600],
    ],
    center: [
        [299, 0],
        [801, 0],
        [801, 600],
        [299, 600],
    ],
};

const stateColors = {
    selected: 'rgb(255, 159, 64)', // nice light orange color
    unselected: 'rgb(135, 206, 250)', // nice light blue color
    assigned: 'rgb(63, 195, 128)', // nice light green color
};

export interface HomographyTool {
    kind: string;
}

export interface Props {
    labels: any[];
    jobInstance: any;
    isActivated: boolean;
    states: any[];
    frame: number;
    frameData: any;

    canvasInstance: Canvas;
    disabled: boolean;
}

interface DispatchToProps {
    createAnnotations(sessionInstance: any, frame: number, statesToCreate: any[]): void;
    onStoreHomographyPoint(): void;
    onDrawStart(label: Label): void;
}

interface State {
    selected: number[] | undefined;
    assigned: number[][];
    selectedField: string;

    // opencv state
    libraryInitialized: boolean;
    initializationError: boolean;
    initializationProgress: number;
}

function mapStateToProps(state: CombinedState): Props {
    const {
        annotation: {
            annotations: { states },
            job: { instance: jobInstance, labels },
            canvas: { activeControl, instance: canvasInstance },
            player: {
                frame: { number: frame, data: frameData },
            },
        },
    } = state;

    return {
        labels,
        jobInstance,
        isActivated: activeControl === ActiveControl.HOMOGRAPHY_TOOL,
        states,
        frame,
        frameData,

        canvasInstance: canvasInstance as Canvas,
        disabled: !labels.length || frameData.deleted,
    };
}

const mapDispatchToProps = {
    createAnnotations: createAnnotationsAsync,
    onStoreHomographyPoint: storeHomographyPoint,
};

const CustomPopover = withVisibilityHandling(Popover, 'homography-tool');

class HomographyToolComponent extends React.PureComponent<Props & DispatchToProps, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            selected: undefined,
            assigned: [],
            selectedField: 'football',

            libraryInitialized: openCVWrapper.isInitialized,
            initializationError: false,
            initializationProgress: -1,
        };
    }

    public componentWillUnmount(): void {
        openCVWrapper.removeProgressCallback();
    }

    private async initializeOpenCV(): Promise<void> {
        try {
            this.setState({
                initializationError: false,
                initializationProgress: 0,
            });
            await openCVWrapper.initialize((progress: number) => {
                this.setState({ initializationProgress: progress });
            });
            const trackers = Object.values(openCVWrapper.tracking);
            this.setState({
                libraryInitialized: true,
                activeTracker: trackers[0],
                trackers,
            });
        } catch (error: any) {
            notification.error({
                description: error.toString(),
                message: 'Could not initialize OpenCV library',
            });
            this.setState({
                initializationError: true,
                initializationProgress: -1,
            });
        }
    }

    private drawTemplatePoints(points: number[][]): JSX.Element[] {
        const { canvasInstance, onStoreHomographyPoint, states } = this.props;

        const homographyPoints = this.homographyPointsFromAnnotations(states).map((point) => point.slice(2, 4));

        return points.map((point, index) => {
            const isAssigned = homographyPoints.some(
                (assignedPoint) => assignedPoint[0] == point[0] && assignedPoint[1] == point[1],
            );

            const dynamicCircleProps = {
                onClick: () => {
                    const initialState = this.createObjectFromData({
                        type: 'points',
                        occluded: false,
                        outside: false,
                        z_order: 0,
                        points: [],
                        label: 'homography_point',
                        attributes: [
                            { name: 'template_point_x', value: point[0].toString() },
                            { name: 'template_point_y', value: point[1].toString() },
                        ],
                    });

                    canvasInstance.cancel();
                    canvasInstance.draw({
                        enabled: true,
                        numberOfPoints: 1,
                        shapeType: ShapeType.POINTS,
                        skeletonSVG: undefined,
                        crosshair: false,
                        initialState: initialState,
                    });
                    onStoreHomographyPoint();
                },
            };

            return (
                <circle
                    {...dynamicCircleProps}
                    key={`point_${index}`}
                    cx={point[0]}
                    cy={point[1]}
                    r='10'
                    fill={isAssigned ? stateColors.assigned : stateColors.unselected}
                />
            );
        });
    }

    private createObjectFromData(data: any): any {
        const { frame, jobInstance } = this.props;

        const jobLabel = (jobInstance.labels as Label[]).find((jLabel: Label): boolean => jLabel.name === data.label);

        let elements = null;
        if (data.elements !== null && jobLabel.structure !== null) {
            elements = data.elements.map((elem: any, idx: any) => {
                elem.label = jobLabel.structure.sublabels[idx];
                elem.frame = frame;
                elem.objectType = ObjectType.SHAPE;
                elem.shapeType = elem.type;
                return elem;
            });
        }

        const attrsMap: Record<string, Record<string, number>> = {};
        jobInstance.labels.forEach((label: any) => {
            attrsMap[label.name] = {};
            label.attributes.forEach((attr: any) => {
                attrsMap[label.name][attr.name] = attr.id;
            });
        });

        const objectData = {
            shapeType: data.type,
            label: jobLabel,
            objectType: ObjectType.SHAPE,
            frame,
            occluded: false,
            source: core.enums.Source.AUTO,
            attributes: (data.attributes as { name: string; value: string }[]).reduce((acc, attr) => {
                acc[attrsMap[data.label][attr.name]] = attr.value;
                return acc;
            }, {} as Record<number, string>),
            zOrder: 0,
            elements: elements,
        };

        const finalObject = new core.classes.ObjectState({
            ...objectData,
            shapeType: data.type,
            points: data.points,
        });

        return finalObject;
    }

    private homographyPointsFromAnnotations(states: any): any {
        return states
            .filter((state: any) => state.label.name === 'homography_point')
            .map((state: any) => {
                let attrMap: any = {};
                state.label.attributes.forEach((attr: any) => {
                    attrMap[attr.name] = attr.id;
                });

                const x = parseFloat(state.attributes[attrMap['template_point_x']]);
                const y = parseFloat(state.attributes[attrMap['template_point_y']]);
                return [...state.points, x, y];
            });
    }

    private async calculateHomography(): Promise<void> {
        const { canvasInstance, states, frame, jobInstance, createAnnotations } = this.props;
        const { selectedField } = this.state;

        const homographyPoints = this.homographyPointsFromAnnotations(states);

        if (!openCVWrapper.isInitialized) {
            notification.error({
                description: <ReactMarkdown>Library initialization in progress, please try again</ReactMarkdown>,
                message: 'Homography Tool',
                duration: 10,
            });
            return;
        }

        if (homographyPoints.length < 4) {
            notification.error({
                description: <ReactMarkdown>Not enought points were marked</ReactMarkdown>,
                message: 'Homography Tool',
                duration: 10,
            });
            return;
        }

        let imagePoints: number[] = [];
        let templatePoints: number[] = [];
        homographyPoints.forEach((point: any) => {
            imagePoints.push(...point.slice(0, 2));
            templatePoints.push(...point.slice(2, 4));
        });

        /*
        Call OpenCV wrapper to calculate homography
        */

        function cvMatToList(mat: any, h: any, w: any): any {
            let result = [];
            for (let i = 0; i < h; ++i) {
                let row = [];
                for (let j = 0; j < w; ++j) {
                    row.push(mat.floatAt(i, j));
                }
                result.push(row);
            }
            return result;
        }

        function perspectiveTransform(pts: any, mat: any): any {
            // pts is list of lists with shape [4, 2]
            // mat is list of list with shape [3, 3]
            // returns lists with shape [4, 2]
            for (let row of pts) {
                row.push(1.0);
            }
            let result = [];
            for (let i = 0; i < pts.length; ++i) {
                let row = [];
                for (let j = 0; j < 3; ++j) {
                    let elem = 0.0;
                    for (let k = 0; k < 3; ++k) {
                        elem += pts[i][k] * mat[k][j];
                    }
                    row.push(elem);
                }
                let z = row.pop();
                for (let j = 0; j < row.length; ++j) {
                    row[j] /= z;
                }
                result.push(row);
            }
            return result;
        }

        try {
            const cv = openCVWrapper.rawCV();

            const imagePointsMat = cv.matFromArray(imagePoints.length / 2, 2, cv.CV_32F, imagePoints);
            const templatePointsMat = cv.matFromArray(templatePoints.length / 2, 2, cv.CV_32F, templatePoints);

            const homographyMatF64 = cv.findHomography(templatePointsMat, imagePointsMat, cv.RANSAC);
            let homographyMat = new cv.Mat();
            homographyMatF64.convertTo(homographyMat, cv.CV_32F);

            // transforms from template coords to image coords
            const templateToImageProjMat = cvMatToList(homographyMat.t(), 3, 3);
            const imageToTemplateProjMat = cvMatToList(homographyMat.inv(0).t(), 3, 3);

            imagePointsMat.delete();
            templatePointsMat.delete();
            homographyMatF64.delete();
            homographyMat.delete();

            const imgWidth = canvasInstance.model.geometry.image.width;
            const imgHeight = canvasInstance.model.geometry.image.height;
            // project points on the template to image coordinates and count (percentage wise)
            // how many points fall inside the image and pick field kind with highest percentage
            const pointsOnTemplate = fieldTemplatePoints[selectedField];
            const projectedPoints = perspectiveTransform(pointsOnTemplate, templateToImageProjMat);
            const selectedKind = _.maxBy(Object.keys(cornerPoints), (kind: string) => {
                const corner = cornerPoints[kind];
                const [tlX, tlY] = corner[0];
                const [brX, brY] = corner[2];
                const [valid, total] = _.zip(pointsOnTemplate, projectedPoints).reduce(
                    ([validCount, totalCount], [templatePoint, projectedPoint]) => {
                        const [tx, ty] = templatePoint;
                        const [px, py] = projectedPoint;

                        const isOnTemplate = tlX <= tx && tx <= brX && tlY <= ty && ty <= brY;
                        const isOnImage = 0 <= px && px <= imgWidth && 0 <= py && py <= imgHeight;

                        return [validCount + (isOnImage && isOnTemplate), totalCount + isOnTemplate];
                    },
                    [0, 0],
                );
                return valid / total;
            });

            const projCorners = perspectiveTransform(cornerPoints[selectedKind], templateToImageProjMat);

            const fieldKindMap = {
                'men-lacrosse': 'lacrosse-man-field',
                football: 'football-field',
            };
            let selectedFieldKind = fieldKindMap[selectedField];
            selectedFieldKind = selectedKind !== 'full' ? `${selectedKind}-${selectedFieldKind}` : selectedFieldKind;

            const data = {
                type: 'skeleton',
                occluded: false,
                outside: false,
                z_order: 0,
                points: [],
                label: 'field',
                attributes: [{ name: 'field_kind', value: selectedFieldKind }],
                elements: [...Array(4).keys()].map((index) => {
                    return {
                        type: 'points',
                        occluded: false,
                        outside: false,
                        z_order: 0,
                        points: projCorners[index],
                        attributes: [],
                    };
                }),
            };

            const finalObject = this.createObjectFromData(data);
            createAnnotations(jobInstance, frame, [finalObject]);
        } catch (error: any) {
            notification.error({
                description: (
                    <ReactMarkdown>
                        Error while calculating homography. Make sure not every point is on the same line. Details:{' '}
                        {error}
                    </ReactMarkdown>
                ),
                message: 'Homography Tool',
                duration: 10,
            });
            return;
        }
    }

    public render(): JSX.Element {
        const { canvasInstance, disabled, isActivated } = this.props;
        const { libraryInitialized } = this.state;

        const dynamicPopoverProps = isActivated
            ? {
                overlayStyle: {
                    display: 'none',
                },
            }
            : {};

        const dynamicIconProps = isActivated
            ? {
                className: 'cvat-homography-tool-control cvat-active-canvas-control',
                onClick: (): void => {
                    canvasInstance.draw({ enabled: false });
                },
            }
            : {};

        return disabled ? (
            <Icon className='cvat-homography-tool-control cvat-disabled-canvas-control' component={HomographyIcon} />
        ) : (
            <CustomPopover
                {...dynamicPopoverProps}
                overlayClassName='cvat-homography-tool-popover'
                placement='right'
                onVisibleChange={(visible: boolean) => {
                    const { initializationProgress } = this.state;
                    if (!visible || initializationProgress >= 0) return;

                    if (!openCVWrapper.isInitialized || openCVWrapper.initializationInProgress) {
                        this.initializeOpenCV();
                    } else if (libraryInitialized !== openCVWrapper.isInitialized) {
                        this.setState({
                            libraryInitialized: openCVWrapper.isInitialized,
                        });
                    }
                }}
                content={
                    <div className='cvat-homography-tool-control-popover-content'>
                        <Row justify='start'>
                            <Col>
                                <Text className='cvat-text-color' strong>
                                    Homography Tool
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Tabs tabBarGutter={8} onChange={(key) => this.setState({ selectedField: key })}>
                                <Tabs.TabPane
                                    key='football'
                                    tab='Football'
                                    className='cvat-homography-tool-control-tabpane'
                                >
                                    <div width='550px' height='300px'>
                                        <svg
                                            id='football-field'
                                            width='550px'
                                            height='300px'
                                            version='1.1'
                                            viewBox='-20 -20 1140 640'
                                        >
                                            <FootballFieldTemplate />
                                            {this.drawTemplatePoints(fieldTemplatePoints['football'])}
                                        </svg>
                                    </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key='men-lacrosse'
                                    tab='Men Lacrosse'
                                    className='cvat-homography-tool-control-tabpane'
                                >
                                    <div width='550px' height='300px'>
                                        <svg
                                            id='men-lacrosse-field'
                                            width='550px'
                                            height='300px'
                                            version='1.1'
                                            viewBox='-20 -20 1140 640'
                                        >
                                            <MenLacrosseFieldTemplate />
                                            {this.drawTemplatePoints(fieldTemplatePoints['men-lacrosse'])}
                                        </svg>
                                    </div>
                                </Tabs.TabPane>
                            </Tabs>
                        </Row>
                        <Row>
                            <Button
                                className='cvat-homography-tool-calculate-button'
                                onClick={async () => {
                                    // TODO: pick correct field to project on the image
                                    await this.calculateHomography();
                                }}
                            >
                                Calculate
                            </Button>
                        </Row>
                    </div>
                }
            >
                <Icon {...dynamicIconProps} component={HomographyIcon} />
            </CustomPopover>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomographyToolComponent);
